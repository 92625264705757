@import url('https://fonts.googleapis.com/css2?family=Ruda&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
h1 {
  font-size: 70px;
  line-height: 80px;
  font-weight: 600;
  color: #242424;
  text-transform: capitalize;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-family: 'Poppins', sans-serif;
  text-align: left;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 42px;
  color: #242424;
  text-transform: capitalize;
  margin: 0;
  text-align: left;
  font-family: 'Poppins', sans-serif;
}

h4 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #242424;
  font-size: 1.3rem;
  line-height: 30px;
  margin-bottom: 1rem;
}

h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-size: 1rem;
}

.flexBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-home {
  @media only screen and (max-width: 600px) {
    height: 50vh !important;
    img {
      height: 50vh !important;
    }
  }
}

.banner-text-overlay {
  position: absolute;
  margin-left: 3em;
  margin-top: 1em;
  word-wrap: break-word;
  h1,
  h2 {
    font-family: 'Poppins', sans-serif;
    color: white !important;
  }
  h1 {
    font-size: 2rem;
    line-height: 0.1em;
    font-weight: 700;
  }
  @media only screen and (max-width: 801px) {
    margin-left: 1em;
    margin-top: 0.5em;
    padding: 0;
    h1 {
      font-size: 0.6rem;
      line-height: 0.1em;
      font-weight: 700;
    }
  }
}

@keyframes banner-text {
  from {
    opacity: 0;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
  }
  to {
    opacity: 0;
    transform: translate(0);
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
  }
}

.customButtons {
  transition: all 0.2s ease;
  font-size: 0.8125rem;
  font-weight: 400;
  padding: 16px 40px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.5;
  height: auto;
  border-radius: 50px;
}

.bannerButton {
  @extend .customButtons;
  margin: 2rem 0;
}

// .whatWeAre {
//   margin: 2rem;
//   padding: 2rem;
//   text-align: left;
//   h2 {
//     color: #242424;
//     font-size: 40px;
//     line-height: 50px;
//     margin: 1rem auto 1.5rem;
//   }
//   .whatWeAreContent {
//     padding-left: 4rem;
//     padding-right: 4rem;
//   }
// }

.WhatAreYouLookingFor {
  text-align: center;
  @media only screen and (max-width: 801px) {
    margin: 2rem;
    padding: 1rem;
    text-align: center;
    img {
      width: 100%;
    }
    h2 {
      font-size: 2rem !important;
      font-weight: 700 !important;
    }
  }
  margin: 2rem;
  padding: 2rem;
  h2 {
    color: #242424;
    font-weight: 500;
    font-family: 'Ruda', sans-serif;
    font-size: 2.5rem;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
  }
  p {
    font-size: 1.2rem;
    line-height: 0.6rem;
  }

  .my-img-card {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition-duration: 700ms !important;
    animation-duration: 700ms;
    .head-card {
      width: 100%;
      min-height: 300px;
    }
    img {
      width: 100%;
      height: 100%;
    }
    p {
      font-size: 1rem;
      margin-top: 0.3rem;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      p {
        color: #fcbb15;
      }
    }
  }
}

.WhyChooseUs {
  margin: 2rem 2rem 0 2rem;
  padding: 2rem 2rem 0 2rem;
  h2 {
    color: #242424;
    font-weight: 700;
    font-family: 'Ruda', sans-serif;
    font-size: 4rem;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
  }
  p {
    font-size: 1.7rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
    display: flex;
    flex-direction: column;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  img {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .WhyChooseUs {
    text-align: center;
    h2 {
      font-weight: 700;
      font-size: 1.9rem;
      letter-spacing: 0;
      text-transform: uppercase;
    }
    img {
      width: 100%;
    }
    p {
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0;
      display: flex;
      flex-direction: column;
    }
  }
  .OurDigitalMarketingSolutions {
    margin: 0.2rem;
    padding: 0.2rem;
    text-align: center;
    h2 {
      font-weight: 700 !important;
      font-size: 2rem !important;
    }
    img {
      width: 100% !important;
    }
  }
}

.OurDigitalMarketingSolutions {
  margin: 2rem;
  padding: 2rem;
  h2 {
    color: #242424;
    font-weight: 500;
    font-family: 'Ruda', sans-serif;
    font-size: 2.5rem;
    text-transform: uppercase;
    text-align: center;
  }
  img {
    width: 60%;
  }
  .my-img-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition-duration: 700ms;
    p {
      font-size: 1rem;
      margin-top: 0.3rem;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      p {
        color: #fcbb15;
      }
    }
  }
}
.ant-dropdown {
  z-index: 999999999 !important;
}
.OurPerformance {
  @media only screen and (max-width: 600px) {
    text-align: center;
    h2 {
      font-weight: 700 !important;
    }
  }
  margin: 2rem 0;
  padding: 2rem 0;
  h2 {
    color: #242424;
    font-weight: 400;
    font-family: 'Ruda', sans-serif;
    font-size: 2rem;
    text-transform: uppercase;
  }
  .my-band {
    background-color: #fcbb15;
    padding: 2rem 7rem;
    .my-band-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      .text-number {
        font-weight: 700;
        font-size: 3rem;
        font-family: cursive;
      }
      .text-desc {
        font-weight: 700;
        font-size: 1rem;
      }
    }
  }
}

.CaseStudy {
  @media only screen and (max-width: 600px) {
    margin: 0.2rem;
    padding: 0.2rem;
    h2 {
      font-size: 2rem !important;
      font-weight: 700 !important;
    }
  }
  margin: 2rem;
  padding: 2rem;
  text-align: center;
  h2 {
    color: #242424;
    font-weight: 400;
    font-size: 3rem;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
  }
  .case-study-card {
    margin: 0 1rem;
    border: none;
    width: 300px;
    .small-logo {
      transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
    }
    .img-lg {
      width: 100%;
      height: 300px;
      padding: 4rem 0.5rem;
    }
    a {
      display: none;
      opacity: 0;
      font-size: 1rem;
    }
    img {
      transition: filter 0.3s ease-in;
      -webkit-transition: filter 0.3s ease-in;
      -moz-transition: filter 0.3s ease-in;
      -ms-transition: filter 0.3s ease-in;
      -o-transition: filter 0.3s ease-in;
    }

    transition: background-color 0.3s ease-in;

    &:hover {
      background-color: black;
      border-radius: 1rem !important;
      -webkit-border-radius: 1rem !important;
      -moz-border-radius: 1rem !important;
      -ms-border-radius: 1rem !important;
      -o-border-radius: 1rem !important;

      .black-img {
        filter: invert(100%);
        -webkit-filter: invert(100%);
      }
      a {
        display: block;
        opacity: 1;
        text-decoration: underline;
        color: white;
      }
    }
  }
}

// .ourServices {
//   text-align: center;
//   padding: 2rem 4rem;
//   margin: 6rem 8rem 1rem 8rem;
//   h2 {
//     font-size: 2.5rem !important;
//     text-align: center;
//     margin-bottom: 3.5rem;
//   }
//   .ourServicesWrapper {
//     display: flex;
//     justify-content: center;
//     text-align: left;
//     margin: 0 auto;
//     .ant-card {
//       border: none;
//       font-size: 1.1rem;
//       text-align: left;
//     }

//     .ant-card-meta-avatar {
//       font-size: 3rem;
//       color: #aaaaaa;
//     }

//     .ant-card-meta-detail {
//       .ant-card-meta-title {
//         white-space: pre-line;
//       }
//     }

//     h4 {
//       font-size: 1.25rem;
//     }

//     .ant-card-body:hover {
//       p:hover {
//         color: #f75757;
//       }
//       h4:hover {
//         color: #f75757;
//       }
//       span:hover {
//         color: #f75757;
//       }
//     }
//   }
// }

.clientsTestimony {
  padding: 6rem 0;
  max-width: 960px;
  margin: 0 auto;
  text-align: left !important;
  font-weight: 600;
  max-width: 70%;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 4rem;
  .ant-card-meta-title {
    white-space: normal;
  }
  .ant-card {
    border: none;
    flex: 1 0 33%;
  }
  p {
    font-size: 20px;
    line-height: 38px;
    color: #242424;
    margin-bottom: 30px;
    font-style: italic;
  }
  .ant-carousel .slick-list .slick-slide > div > div {
    display: flex !important;
  }
}

.readyToGrow {
  background-color: #f5f8f9;
  padding: 3rem;
  border-radius: 0.25rem;

  h2 {
    text-align: center;
    padding: 0 2rem 2rem 2rem;
    margin-bottom: 0.25rem;
  }
}

.container {
  margin: 0 auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (max-width: 768px) {
  .whatWeAre {
    margin: 1rem 0;
    padding: 0;

    .whatWeAreContent {
      padding: 2rem;
    }
  }
}

@media (max-width: 1200px) {
  .ourServices {
    padding: 2rem 2rem;
    margin: 6rem 4rem 1rem 4rem;
  }
}

@media (max-width: 576px) {
  .ourServices {
    margin: 2rem 0.15rem 0 0.15rem;
    padding: 0.25rem;

    h2 {
      margin: 2.5rem 0rem;
    }

    .ant-card-body {
      padding: 0;
    }
  }
}

.blog {
  .card-image-blog {
    width: 100%;
    height: 450px;
    object-fit: cover;
  }
  .banner-blog {
    width: 100%;
    height: 70vh;
    background-image: url('../../assets/blog/blog-banner.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.3);
    }
    .banner-text {
      position: absolute;
      background-color: rgba($color: #000000, $alpha: 0.7);
      color: white;
      padding: 1rem 5rem;
      top: 50%;
      left: 50%;
      font-size: 2rem;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.3em;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
    }
  }
  .blog-category {
    span {
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 500;
      font-family: Arial, Helvetica, sans-serif;
    }
    ul {
      list-style: none;
      padding: 0 5rem;
      font-size: 1rem;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      text-transform: uppercase;
      text-align: justify !important;
    }
  }
  .blog-card {
    padding: 0 1rem;
    margin-top: 1rem;
  }
}
.pagination-blog a {
  font-size: 1.2rem;
  font-family: cursives;
  color: #969696;
}
.pagination-blog a span {
  opacity: 0;
}
.pagination-blog a:hover span {
  opacity: 1;
}
.pagination-blog a:hover {
  color: #1d1d1d;
}

@media only screen and (max-width: 600px) {
  .single-blog {
    .blog-content-container {
      padding: 1rem !important;
    }
    .blog-title {
      font-size: 2rem !important;
    }
  }
}

.single-blog {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    clear: both !important;
    color: #1d1d1d !important;
    margin: 16px 0 !important;
    line-height: 1.3 !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: 600 !important;
  }
  .blog-content-container {
    padding: 1rem 15rem;
    text-align: justify;
  }
  .entry-content {
    margin-top: 0;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 0.5px;
    color: #444;
    font-weight: 400;
  }
  b,
  strong {
    font-weight: bold;
  }
  h1 .page-title {
    font-size: 3rem !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: 600 !important;
  }
  .blog-title {
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 5rem;
    letter-spacing: -2px;
    border-bottom: 2px solid black;
    text-transform: uppercase;
  }
  p {
    font-family: Georgia, serif;
    margin: 0.8em 0;
    color: #555;
    letter-spacing: 0.5px;
    font-size: 18px;
    font-weight: 400;
  }
  ul {
    list-style: disc;
  }
  ul,
  ol {
    margin: 0;
    padding-left: 1.5em;
  }
  .banner-blog {
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.3);
    }
    .banner-text {
      position: absolute;
      background-color: rgba($color: #000000, $alpha: 0.7);
      color: white;
      padding: 1rem 5rem;
      top: 50%;
      left: 50%;
      font-size: 2rem;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.3em;
      text-align: justify;

      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
    }
  }
}

.fadeInUp {
  animation-name: fade;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  animation-iteration-count: 3;
  opacity: 1;
}

.fadeIn {
  animation-name: fadeInLeft;
  animation-duration: 1000ms;
  animation-delay: 0ms;
  animation-iteration-count: 3;
  opacity: 1;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
    transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -moz-transform: translateY(200px);
    -ms-transform: translateY(200px);
    -o-transform: translateY(200px);
  }
  to {
    opacity: 1;
  }
}

.case-study-main {
  @media only screen and (max-width: 800px) {
    .navigus-case-study-big {
      display: none !important;
    }
    .navigus-case-study-small {
      h2,
      h3 {
        color: white !important;
      }
      display: block !important;
      position: absolute !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) !important;
      -webkit-transform: translate(-50%, -50%) !important;
      -moz-transform: translate(-50%, -50%) !important;
      -ms-transform: translate(-50%, -50%) !important;
      -o-transform: translate(-50%, -50%) !important;
    }
  }

  background-color: #fdfcfa;
  .case-study-banner {
    width: 100%;
    height: 70vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .case-study-heading {
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: absolute;
    padding: 1rem 5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    h2 {
      text-transform: uppercase;
      font-size: 2rem;
      color: #fff;
      font-weight: 700;
      letter-spacing: 0.1rem;
      font-family: sans-serif;
    }
  }
  .case-study-body {
    text-align: justify;
    padding: 3rem 10rem;
    font-family: 'Poppins', sans-serif;
    h3 {
      font-size: 2rem;
      color: #4e443c;
      font-variant: small-caps;
      text-transform: none;
      font-weight: 700;
      margin-bottom: 0;
    }
    p,
    li {
      font-size: 1rem;
      font-weight: 400;
      margin-top: 0.5em;
      color: #666;
    }
  }
}

@media only screen and (max-width: 600px) {
  .case-study-body {
    padding: 1rem !important;
  }
}
