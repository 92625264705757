.aboutBanner {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/bg/home-2.jpg") no-repeat;
  background-size: cover;
  background-position: 0;
  position: relative;
  color: #ffffff;
  padding: 100px 0;
  div {
    h1 {
      position: relative;
      text-align: center;
      color: white;
      font-size: 3rem;
    }

    @media (min-width: 768px) {
      h1 {
        font-size: 4rem;
      }
    }
  }
}

h1 {
  font-size: 70px;
  line-height: 80px;
  font-weight: 600;
  color: #242424;
  text-transform: capitalize;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 42px;
  color: #242424;
  text-transform: capitalize;
  margin: 0;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #242424;
  font-size: 1.3rem;
  line-height: 30px;
  margin-bottom: 1rem;
}

h6 {
  color: #f75757;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-size: 1rem;
}

.hl {
  padding: 10px;
  border-bottom: 1px solid #d1d2df;
}

.platformsWrap {
  padding: 4rem;
  margin: 0 auto;
  max-width: 75%;
  .ant-carousel .slick-list .slick-slide > div > div {
    display: flex !important;
  }
  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  .slick-active button {
    margin: 0 !important;
  }
  img {
    max-width: 10rem;
  }
  .slick-dots {
    bottom: -2rem;
    button {
      background-color: #444444 !important;
      height: 1rem !important;
      width: 1rem !important;
      border-radius: 20px !important;
      margin-right: 0 !important;
    }
  }
}

.clientsTestimonyHeader {
  padding-left: 1rem;
}

.clientsTestimonyWrapper {
  margin: 2rem 0;
  .slick-dots {
    bottom: -2rem;
    button {
      background-color: #444444 !important;
      height: 1rem !important;
      width: 1rem !important;
      border-radius: 20px !important;
      margin-right: 0 !important;
    }
    .slick-active button {
      margin: 0 !important;
    }
  }
}

.whatItIncludesWrapper {
  background-color: #f5f8f9;
  padding: 20px 10px;
  .ant-row {
    justify-content: space-around;
  }
  .ant-col > div {
    margin: 2rem;
    display: grid;
    justify-items: center;
  }
  .whatItIncludesIcon {
    width: 3rem;
    img {
      width: 100%;
    }
  }
}

.getQuotesForm {
  h4 {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }
}

.growGetQuotesForm {
  padding-right: 4rem;
}

@media (max-width: 992px) {
  .growGetQuotesForm {
    padding: 2rem;
  }

  .platformsWrap {
    max-width: 100%;
    margin: 1rem;
    padding: 1rem;
  }

  .ant-carousel .slick-slide img {
    display: inline;
  }
}
