.top-navbar {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 1rem 8rem;
  overflow: hidden;
  img {
    width: 230px;
    height: 60px;
  }
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 55%;
    li {
      list-style: none;
    }
    a {
      color: black !important;
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  .burger {
    display: none;
  }
}

.active {
  display: block !important;
  flex-direction: column;
  position: inherit;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: white;
}

@media only screen and (max-width: 768px) {
  .top-navbar {
    padding: 1rem !important;
    flex-direction: column;

    img {
      width: 150px;
      height: 50px;
      margin-left: 4em;
    }
    ul {
      display: none;
    }
    .burger {
      display: inline;
      cursor: pointer;
    }
    .active-logo {
      display: flex;
      width: 100%;
      
    }
  }
}

.sticky {
  position: fixed;
  z-index: 2;
  width: 100%;
  background-color: white;
  top: 0;
}
