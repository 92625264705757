Footer {
  // border: 2px solid black;
  font-size: 1.25rem;
  .upper-footer {
    padding: 1rem;
    .footerList {
      padding: 1rem 2rem;
      // border: 2px solid red;
      text-align: left;
      font-size: 1rem;
      a {
        color: rgba(0, 0, 0, 0.8);
        text-decoration: none;
      }

      a:hover {
        color: #f75757;
      }
      input {
        outline: none;
        background: #f5f8f9;
        margin: 1rem 0;
        font-size: 1rem;
        border-radius: 5px;
      }
      input:active {
        border: 1px solid #f75757;
      }

      button {
        border: none;
        border-radius: 5px;
      }

      button:hover {
        background-color: #c74242;
      }
      a {
        color: #242424;
      }

      a:hover {
        color: #f75757;
      }
    }
    @media (max-width: 768px) {
      .footerList {
        padding: 1rem 0 0 0;
      }
    }
  }

  .lower-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding: 1rem;
    a {
      color: #242424;
      text-decoration: none;
      span {
        padding-left: 0.5rem;
      }
    }

    .copyright {
      text-align: left;
      color: rgba(0, 0, 0, 0.65);
    }

    .socialLinks {
      font-size: 1rem;
    }

    @media (max-width: 768px) {
      .copyright {
        text-align: center;
      }
      .socialLinks {
        padding-top: 0.5rem;
      }
    }
  }
}

@media (min-width: 992px) {
  Footer {
    margin: 0rem 5rem;
    .upper-footer {
      padding: 4rem;
    }
    .lower-footer {
      margin: 0 5rem;
    }
  }
}

@media (max-width: 768px) {
  Footer {
    .lower-footer {
      padding: 1rem 0;
    }
  }
}

.specialText {
  color: #f75757;
}

.footer-address {
  p {
    font-size: 1rem;
    font-weight: 600;
    line-height: 14px;
  }
}
