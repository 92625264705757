header {
  background: #fff;
  color: black;
  border-bottom: 3px solid black;
  text-align: center;
}
.header-all {
  //   border: 1px solid black;
  font-size: 1rem;
  .socialMedia {
    // border: 1px solid green;
    a {
      text-align: left;
      color: black;
      font-size: 1.5rem;
      padding-left: 1rem;
      -webkit-border-radius: 25%;
      -moz-border-radius: 25%;
      -ms-border-radius: 25%;
      -o-border-radius: 25%;
    }
  }
  .contactLink {
    text-align: center;
    // border: 1px solid red;
    a {
      color: black;

      text-decoration: none;
    }
    span {
      padding-right: 0.25rem;
      color: black;
    }
  }
}

@media (min-width: 768px) {
  .header-all {
    margin: 0rem 6rem;

    .socialMedia {
      text-align: left;
    }
  }
}

@media only screen and (max-width: 601px) {
  .top-nav-bar{
    display: none !important;
  }
}
